'use client'

import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import colors from '@studygenius/shared/Colors';

const theme = createTheme({
    palette: {
      primary: {
        main: colors.main,
      },
      secondary: {
        main: colors.lightGreen,
      },
      white: {
        main: colors.white,
      }
    },
    components: {
      MuiBackdrop: {
        // The properties you want to change
        styleOverrides: {
          root: {
            // Your custom styles
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Example for red backdrop with 50% opacity
          },
        }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.38)', // Adjust the alpha for darker or lighter
          '&.MuiSkeleton-wave': {
            // You may not need this unless you use wave animation and want different customization
            // backgroundImage: 'linear-gradient(90deg, transparent, rgba(0,0,0,0.51), transparent)',
          }
        }
      
  }
}
    }
 
  });

export const MyThemeProvider = (props) => {
    return <ThemeProvider theme={theme}>
    {props.children}</ThemeProvider>
}