'use client';

import { useEffect, useState, createContext, useContext } from 'react';
import { useRouter } from 'next/router';
import firebase from '@studygenius/shared/firebaseSetup';
import {createUser, setSubscription} from '@studygenius/shared/Requests';
import { SET_USER_PROFILE } from '@studygenius/shared/Redux/actions';
import {useDispatch, useSelector, authentication, subscriptionOptions, getActiveSubscriptionOption} from '@studygenius/shared/Variables';
import {getUser, getCustomerSubscription} from '@studygenius/shared/Requests';
import { getAuth, setPersistence, onAuthStateChanged, browserLocalPersistence, signInWithEmailAndPassword, signInAnonymously } from 'firebase/auth';
import { getDatabase, ref, set, onValue, child, get, off, onChildAdded, onChildChanged, update } from 'firebase/database';

const FirebaseContext = createContext();

function FirebaseComponent({ children }) {

    const setUserProfile = (userProfile) => dispatch(SET_USER_PROFILE(userProfile));
    const [hasFetched, setHasFetched] = useState(false);

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.websocket.userProfile);

    const handleSubscription = async(fetchUser) => {
        console.log('inside handle subscription')
        console.log('fetchUser', fetchUser)

        const subscriptions = await getCustomerSubscription(fetchUser.stripeCustomerId);
        console.log('subscriptions', subscriptions)

        const subscription = subscriptions.data?.[0];
        // if(!subscription) return;
        console.log('subscription is', subscription)
        if(!fetchUser.activeSubscription || fetchUser.activeSubscription?.endDate !== subscription?.current_period_end) {
            const thisOption = getActiveSubscriptionOption(subscription?.plan?.id) //Object.values(subscriptionOptions).find((option) => option.PRICE_ID === subscription?.plan.id);
            
            if(!thisOption) return fetchUser
            const newActiveSubscription = {
                coins: thisOption.COINS,
                endDate: subscription?.current_period_end,
                subscriptionId: subscription?.id,
                subscriptionPlan: subscription?.plan?.id,
                lastUpdated: Date.now()
            }
            await setSubscription(fetchUser.id, newActiveSubscription)
            fetchUser.activeSubscription = newActiveSubscription;
        }
        return fetchUser;
    }
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(authentication(), async (user) => {
            
            const db = getDatabase(firebase);
            const userRef = ref(db, 'users/' + userProfile?.id);
            if (user) {
                
                
                if (userProfile?.id !== user?.uid || !hasFetched) {
                    
                    

            
                    const listener = onValue(userRef, async(snapshot) => {
                        
                        let user = snapshot.val();

                        if(user?.stripeCustomerId) {
                            user = await handleSubscription(user)
                        }
                        if (user?.id && user?.id !== userProfile?.id) {
                            setUserProfile(user);

                        }
                        
                    });
                    
                    setTimeout(async() => {
                        let fetchUser = await getUser(user.uid);
                        
                        
                        if(fetchUser.stripeCustomerId) {
                            fetchUser = await handleSubscription(fetchUser)

                        }
                        dispatch(SET_USER_PROFILE(fetchUser));
                    }, 0);
                    setHasFetched(true);
                }
            } else {
                
                const u = await signInAnonymously(authentication());
                
                const id = u.user.uid;
                
                const createdUser = await createUser(id);
                
                dispatch(SET_USER_PROFILE(createdUser));
                setHasFetched(true);

           
                const listener = onValue(userRef, (snapshot) => {
                    

                    setUserProfile(snapshot.val());
                });

            }
        });
        
        // Clean up subscription on unmount
        return () => unsubscribe();
    }, [hasFetched, userProfile?.id, dispatch, onAuthStateChanged, authentication()]);
    


    
    return <FirebaseContext.Provider value={{ hasFetched }}>{children}

    </FirebaseContext.Provider>;
}

export default FirebaseComponent;

export const useFirebase = () => useContext(FirebaseContext);