'use client';

import { WS_CONNECT, WS_MESSAGE_SEND, WS_DISCONNECT, WS_CONNECTED, WS_MESSAGE_RECEIVED, WS_DISCONNECTED, CLEAR_MESSAGES, SHOULD_UPDATE, CURRENT_FACT, UPDATE_NEW_FACT_FOCUS_STATUS, ADD_PENDING_FACT_UPDATE, PROCESS_PENDING_FACT_UPDATE, CLEAR_PENDING_FACT_UPDATE, UPDATE_LANGUAGE, HAS_GOTTEN_USER_POINTS } from './actions';
import { createDraft, createFact } from '../Requests';
export var socket = null;
var messageQueue = [];
var createFactWithStore = function createFactWithStore(store) {
  var _store$getState$webso, _store$getState;
  if (!((_store$getState$webso = store.getState().websocket) !== null && _store$getState$webso !== void 0 && _store$getState$webso.currentFact)) {
    return;
  }
  var messages = store.getState().websocket.messages;
  if (!messages) {
    return;
  }
  var string = '';
  messages.forEach(function (message) {
    return string += message.payload;
  });
  var _store$getState$webso2 = store === null || store === void 0 || (_store$getState = store.getState()) === null || _store$getState === void 0 || (_store$getState = _store$getState.websocket) === null || _store$getState === void 0 || (_store$getState = _store$getState.currentFact) === null || _store$getState === void 0 ? void 0 : _store$getState.payload,
    userProfile = _store$getState$webso2.userProfile,
    groupName = _store$getState$webso2.groupName,
    id = _store$getState$webso2.id;
  if (!userProfile || !groupName || !id) return;
  createFact(userProfile, string, groupName, id);
  store.dispatch(CLEAR_MESSAGES());
};
export var wsMiddleware = function wsMiddleware(store) {
  return function (next) {
    return function (action) {
      switch (action.type) {
        case WS_CONNECT.type:
          var url = action.payload.host;
          socket = new WebSocket(url);
          socket.onopen = function () {
            store.dispatch(WS_CONNECTED({
              payload: {
                isConnected: true
              }
            }));
            messageQueue.forEach(function (message) {
              socket.send(JSON.stringify(message));
            });
            messageQueue = [];
          };
          socket.onmessage = function (event) {
            if (typeof event.data === 'object') {
              return store.dispatch(WS_DISCONNECT());
              // store.dispatch(PROCESS_PENDING_FACT_UPDATE());

              // createFactWithStore(store);

              // store.dispatch(CURRENT_FACT(false));

              // store.dispatch(CLEAR_PENDING_FACT_UPDATE());
            }

            if (store.getState().websocket) {
              store.dispatch(WS_MESSAGE_RECEIVED({
                payload: event.data
              }));
            } else {
              store.dispatch(ADD_PENDING_FACT_UPDATE({
                payload: event.data
              }));
            }
          };
          break;
        case SHOULD_UPDATE.type:
          if (action.payload.isTrue === true) {
            if (action.payload.createDraft) {
              createDraft(action.payload.groupName, action.payload.id, action.payload.userProfile.id);
            }
            store.dispatch(CURRENT_FACT({
              payload: action.payload
            }));
          }
          break;
        case WS_MESSAGE_SEND.type:
          if (socket.readyState !== WebSocket.OPEN) {
            messageQueue.push(action.payload);
            break;
          }
          socket.send(JSON.stringify(action.payload));
          break;
        case WS_DISCONNECT.type:
          store.dispatch(PROCESS_PENDING_FACT_UPDATE());
          createFactWithStore(store);
          store.dispatch(CURRENT_FACT(false));
          store.dispatch(CLEAR_PENDING_FACT_UPDATE());
          if (socket) {
            socket.close();
          }
          break;
        case WS_DISCONNECTED.type:
          socket = null;
          break;
        default:
          return next(action);
      }
    };
  };
};