import { configureStore } from '@reduxjs/toolkit';
import { wsReducer } from './reducers';
import { wsMiddleware } from './middlewares';
export var store = function store() {
  var reducer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return configureStore({
    reducer: {
      websocket: reducer || wsReducer
    },
    middleware: function middleware(getDefaultMiddleware) {
      return getDefaultMiddleware().concat(wsMiddleware);
    }
  });
};