'use client';

import { Provider } from 'react-redux';

/* Instruments */
import { store } from '../../../shared/Redux/store';
import { createReducer } from '@reduxjs/toolkit';

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {wsReducer} from '../../../shared/Redux/reducers';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
    key: 'root',
    storage,
};


const persistedReducer = persistReducer(persistConfig, wsReducer);

const persistedStore = store(persistedReducer);

const storeInstance = store();

// export const persistor = persistStore(persistedStore);

export const Providers = (props) => {
    return <Provider store={storeInstance}>{props.children}</Provider>;
};