"use client"
import ReactGA from "react-ga4";
import { GA_MEASUREMENT_ID } from "@studygenius/shared/Variables";
import LogRocket from 'logrocket';
import { environment } from "@studygenius/shared/Variables";
const initializeGA = () => {
  // Replace with your Measurement ID
  // It ideally comes from an environment variable
  ReactGA.initialize(GA_MEASUREMENT_ID);
  // Don't forget to remove the  statements
  // when you are done
  
  if (typeof window !== 'undefined') {
    environment() === 'production' && LogRocket.init('xxazmm/web-application-h3xca');
  }
};

const trackGAEvent = (category, action, label) => {
  
  // Send GA4 Event
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export default initializeGA;
export { initializeGA, trackGAEvent }