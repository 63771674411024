import { createAction } from '@reduxjs/toolkit';
export var WS_CONNECT = createAction('WS_CONNECT');
export var WS_MESSAGE_SEND = createAction('WS_MESSAGE_SEND');
export var WS_DISCONNECT = createAction('WS_DISCONNECT');
export var WS_CONNECTED = createAction('WS_CONNECTED');
export var WS_MESSAGE_RECEIVED = createAction('WS_MESSAGE_RECEIVED');
export var WS_DISCONNECTED = createAction('WS_DISCONNECTED');
export var CLEAR_MESSAGES = createAction('CLEAR_MESSAGES');
export var SHOULD_UPDATE = createAction('SHOULD_UPDATE');
export var CURRENT_FACT = createAction('CURRENT_FACT');
export var UPDATE_NEW_FACT_FOCUS_STATUS = createAction('UPDATE_NEW_FACT_FOCUS_STATUS');
export var ADD_PENDING_FACT_UPDATE = createAction('ADD_PENDING_FACT_UPDATE');
export var PROCESS_PENDING_FACT_UPDATE = createAction('PROCESS_PENDING_FACT_UPDATE');
export var CLEAR_PENDING_FACT_UPDATE = createAction('CLEAR_PENDING_FACT_UPDATE');
export var SET_USER_PROFILE = createAction('SET_USER_PROFILE');
export var UPDATE_ITERATION = createAction('UPDATE_ITERATION');
export var SET_CURRENT_ITERATION = createAction('SET_CURRENT_ITERATION');
export var SET_USER_FACT_GROUP = createAction('SET_USER_FACT_GROUP');
export var SET_USER_SUBSCRIPTION = createAction('SET_USER_SUBSCRIPTION');
export var SET_USER_FACT = createAction('SET_USER_FACT');
export var UPDATE_LANGUAGE = createAction('UPDATE_LANGUAGE');
export var SET_GAME_CHARACTERS = createAction('SET_GAME_CHARACTERS');
export var UPDATE_CHARACTERS = createAction('UPDATE_CHARACTERS');
export var HAS_GOTTEN_USER_POINTS = createAction('HAS_GOTTEN_USER_POINTS');
export var SET_HAS_USER_CREDIT_CARD = createAction('SET_HAS_USER_CREDIT_CARD');
export var ADD_USED_RESOURCE = createAction('ADD_USED_RESOURCE');
export var ADD_TO_COMPLETED_ITERATIONS = createAction('ADD_TO_COMPLETED_ITERATIONS');